.agent-row {
 margin-top:2rem;
 margin-bottom:2rem;
}

.agent-container, .agent-container-mobile {
    margin:1rem auto !important;
    padding:2rem;
    border:1px solid #dee2e6;
    border-radius:.25rem;
}

.agent-container {
    width:75%;
}

.agent-container-mobile {
    width:90%;
}

.agent-photo {
    float:left;
    border-radius:.25rem;
    width:12.5rem;
    margin-right:2rem;
}

.agent-name {
    margin-top:-.25rem;
    font-weight:400 !important;
    font-size:2rem !important;
}

.agent-title {
    font-weight:300 !important;
    font-size:1rem !important;
    font-style:italic;
}

.agent-description, .agent-description-mobile {
    text-align: justify;
}

.agent-description-mobile {
    font-size:.875rem !important;
}