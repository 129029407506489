.nav-logo {
    width:20rem !important;
  }

.nav-logo-mobile {
    width:10rem !important;
  }

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23F0F0F0' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e") !important;
    width:1.5rem !important;
    height:1.5rem !important;
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23F0F0F0' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e") !important;
    width:1.5rem !important;
    height:1.5rem !important;
}

.off-main-background, .off-main-background-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
    background-image:url('../images/off-main-background.jpg') !important;
    background-size:100%;
    background-repeat:no-repeat;
    box-shadow: 0 4px 4px -4px rgba(0, 0, 0, .25);
}

.off-main-background {
    height:12.5rem;
}

.off-main-background-mobile {
    height:7.5rem;
}

.rental-price {
    margin-top:-.35rem;
    font-size:2rem !important;
    font-weight:400 !important;
    font-family: 'Work Sans', sans-serif;
}

.property-container, .property-container-mobile {
    margin:2rem auto !important;
    padding:2rem 0;
    border:1px solid #dee2e6;
    border-radius:.25rem;
}

.property-container {
    width:80% !important;
}

.property-container-mobile {
    width:97.5% !important;
}

.property-tabs.nav-tabs {
    border-bottom:none !important;
    font-weight:bold !important;
    font-size:.9rem !important;
}

.tab-content{
    border-top:1px solid #dee2e6  !important; 
    border-left:1px solid #dee2e6  !important; 
    border-right:1px solid #dee2e6 !important; 
    border-bottom:1px solid #dee2e6  !important; 
    border-top-right-radius:.25rem !important;
    border-bottom-left-radius:.25rem !important;
    border-bottom-right-radius:.25rem !important;
}

.page-title, .page-title-mobile {
    color: #fff;
    font-family: 'Work Sans', sans-serif;
    font-weight:400 !important;
    text-align:center;
}

.page-title {
    font-size:2.5rem !important;
}

.page-title-mobile {
    font-size:1.75rem !important;
}

.page-subtitle, .page-subtitle-mobile {
    color: #fff;
    font-family: 'Work Sans', sans-serif;
    font-weight:400 !important;
    text-align:center;
}

.page-subtitle {
    font-size:1.25rem !important;
}

.page-subtitle-mobile {
    font-size:.875rem !important;
}

.rental-list-group > .list-group-item > p {
    margin-bottom:.25rem !important;
}

.rental-info-button, .rental-info-button-mobile {
    padding: .5rem .75rem !important;
    font-size: .75em !important;
    font-weight: 500 !important;
    text-transform: uppercase;
    background-color: #003497 !important;
    border-color:#003497 !important;
}

.rental-info-button-mobile {
    position:absolute;
    bottom:0;
    left:1rem;
}

.rental-info-button:hover {
    background-color: #003597cb !important;
    border-color: #003597cb !important;
}

.rental-address {
    text-transform:uppercase;
    font-family: 'Work Sans', sans-serif;
}

.rental-description {
    font-family: 'Roboto', sans-serif;
}

.no-properties, .no-properties-mobile {
    margin:4rem 0;
    text-align: center;
}

.no-properties {
    font-size:2rem !important;
}

.no-properties-mobile {
    font-size:1.5rem !important;
}