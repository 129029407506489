html, body, #root {
  overflow-x: hidden;
}

.vo-navbar {
    width:100%;
    background-color:#fefefe;
    box-shadow: 0 -4px 4px -5px rgba(0, 0, 0, .2);
}

.vo-logo, .vo-logo-mobile {
  box-shadow:0 0 8px rgba(0, 0, 0, .2)
}

.vo-logo {
  width:15rem;
  margin:0 3rem;
}

.vo-logo-mobile {
  width:10rem;
}

.vo-nav-link {
    position:relative;
    margin:0 1rem;
    padding: .25rem .5rem !important;
    color:#041b3a;
    font-size:.875rem !important;
}

.vo-nav-link-mobile {
  position:relative;
  margin:0 1rem;
  padding: .25rem .25rem !important;
  color:#041b3a;
  font-size:.775rem !important;
}

.vo-dropdown-link > a {
    color:#041b3a;
}

.vo-nav-link:hover, .vo-dropdown-link > a:hover {
    color:#041b3a;
  }

.vo-nav-link:before {
    content: "";
    position: absolute;
    width: 100%;
    height: .2rem;
    bottom: -.2rem;
    left: 0;
    background-color:#041b3a94;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
    
}

.vo-nav-link:hover:before {
    visibility: visible;
    transform: scaleX(1);
  }

  .nav-email-button {
    position: absolute;
    right: 2rem;
    padding:.25rem 2rem !important;
    background-color: #0947a5 !important;
    border-color: #0947a5 !important;
  }

  .nav-email-button:hover {
    background-color: #003597cb !important;
    border-color: #003597cb !important;
  }

  .flip-arrows {
    width:5rem !important;
    animation:rotation 2s infinite linear;
    -webkit-animation: rotation 2s infinite linear;
  }
  
  @keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
  }
  