h1 {
    font-family: 'Work Sans', sans-serif;
    font-size:3rem;
}

.vo-realty-background {
    width:100%;
    height:15rem;
    /*background-image:url('../images/vo-background.jpg') !important;*/
    background-size:100% !important;
    background-repeat: no-repeat;
}

.main-slider {
    transition-duration: 3s !important;
}

.jumbotron {
    width:100%;
    height:27.5rem;
    border-radius:0 !important;
    background-image:url('../images/vo-background.jpg') !important;
    background-size:cover;
    box-shadow: 0 4px 4px -4px rgba(0, 0, 0, .25);
    z-index:-1;
    font-family: 'Work Sans', sans-serif;
}

.jumbotron > h3 {
    font-weight:400 !important;
    margin-top:1rem;
    text-transform: uppercase;
    font-size:2rem;
}

.main-card {
    position:relative;
    margin:auto;
}

.main-card-button {
    font-size: .875rem !important;
    background-color: #003497 !important;
    border-color: #003497 !important;
}

.main-card-button:hover {
    background-color: #003597cb !important;
    border-color: #003597cb !important;
}

.footer-container {
    width:100%;
    background-color: #fafafa !important;
    box-shadow: 0 -4px 4px -4px rgba(0, 0, 0, .2);
}

.footer {
    padding:3rem 1rem;
}

.footer-nav-link {
    color: #fff !important;
}
  
.vo-navbar {
    width:100%;
    background-color:#fefefe;
    box-shadow: 0 -4px 4px -5px rgba(0, 0, 0, .2);
}

.vo-nav-link {
    position:relative;
    margin:0 1rem;
    padding: .25rem .5rem !important;
    color:#041b3a;
    font-size:.875rem !important;
}

.vo-nav-link-mobile {
position:relative;
margin:0 1rem;
padding: .25rem .25rem !important;
color:#041b3a;
font-size:.775rem !important;
}

.vo-dropdown-link > a {
    color:#041b3a;
}

.vo-nav-link:hover, .vo-dropdown-link > a:hover {
    color:#041b3a;
}

.vo-nav-link:before {
    content: "";
    position: absolute;
    width: 100%;
    height: .2rem;
    bottom: -.2rem;
    left: 0;
    background-color:#041b3a94;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
    
}

.vo-nav-link:hover:before {
    visibility: visible;
    transform: scaleX(1);
}
  
.nav-email-button {
    position: absolute;
    right: 2rem;
    padding:.25rem 2rem !important;
    background-color: #0947a5 !important;
    border-color: #0947a5 !important;
}

.nav-email-button:hover {
    background-color: #003597cb !important;
    border-color: #003597cb !important;
}

.flip-arrows {
    width:5rem !important;
    animation:rotation 2s infinite linear;
    -webkit-animation: rotation 2s infinite linear;
}

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}

.nav-logo-mobile {
    width:10rem !important;
}

.main-title {
    margin-left:4rem;
    font-size:1.75rem !important;
}

.main-title-mobile {
    font-size:1.35rem !important;
}

.main-subtitle {
    float: left;
    margin: 2rem 1rem 0 4rem;
    font-size:1.1rem !important;
}

.main-subtitle-mobile {
    float: left;
    margin: 2.25rem 1rem 0 0;
    font-size:1rem !important;
}

.main-list-item {
    color: rgb(255, 66, 14)
}

.main-list-item-mobile {
    font-size:1rem !important;
    color: rgb(255, 66, 14)
}